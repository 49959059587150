import WebFont from 'webfontloader'

let styleElement

export const appendMerchantStyles = (merchant: any) => {
  if (styleElement) {
    styleElement.remove()
  }

  styleElement = document.createElement('style')

  if (merchant.primaryColor) {
    styleElement.appendChild(
      document.createTextNode(`
      .btn:not(.btn--danger):not(.btn--disabled):not(.btn--dark):not(.btn--light) {
        background-color: ${merchant.primaryColor} !important;
      }
      .btn--link:not(.btn--danger):not(.btn--disabled):not(.btn--dark):not(.btn--light) {
        background-color: transparent !important;
        color: ${merchant.primaryColor} !important;
      }
      .btn--link:not(.btn--dark) .icon,
      .btn--link:not(.btn--dark) .icon::before {
        color: ${merchant.primaryColor} !important;
      }
      .link--primary {
        color: ${merchant.primaryColor} !important;
      }
      .checkbox:checked + label::before {
        background: ${merchant.primaryColor} !important;
      }

      .calendar__month__day__circle--existing:hover:not(.calendar__month__day__circle--passed),
      .calendar__month__day__circle--selected {
        background-color: ${merchant.primaryColor} !important;
        border-color: ${merchant.primaryColor} !important;
      }
      .alert--success, .alert__icon--success {
        background: ${merchant.primaryColor} !important;
      }
      .alert__icon--success {
        filter: brightness(127%);
      }
    `)
    )
  }

  if (merchant.dangerColor) {
    styleElement.appendChild(
      document.createTextNode(`
      .page--review__card-wrapper.page--review__card-wrapper--invalid,
      .input.input--invalid .vue-tel-input,
      .input.input--invalid input {
        border-color: ${merchant.dangerColor} !important;
      }
      .input__error {
        color: ${merchant.dangerColor} !important;
      }
      .btn--danger:not(.btn--link) {
        background-color: ${merchant.dangerColor} !important;
      }
      .btn--danger.btn--link {
        color: ${merchant.dangerColor} !important;
      }
      .btn--danger.btn--link .icon {
        color: ${merchant.dangerColor} !important;
      }
      .btn--danger.btn--link .icon::before {
        color: ${merchant.dangerColor} !important;
      }
    `)
    )
  }

  if (merchant.confirmColor) {
    styleElement.appendChild(
      document.createTextNode(`
      .page--confirm__header {
        background-color: ${merchant.confirmColor} !important;
      }
      .page--review__header__timer {
        background-color: ${merchant.confirmColor} !important;
      }
      @media (max-width: 47.9375rem) .page--review__header__timer {
        color: ${merchant.confirmColor} !important;
      }
    `)
    )
  }

  if (merchant.fontFaceName && merchant.fontFaceUrl) {
    const webFontType = merchant.fontFaceUrl.match('fonts.google')
      ? 'google'
      : 'custom'

    WebFont.load({
      [webFontType]: {
        families: [merchant.fontFaceName],
        urls: [merchant.fontFaceUrl],
      },
    })

    styleElement.appendChild(
      document.createTextNode(`
      body *:not(input):not(select):not(option):not(.dropdown-item) {
        font-family: '${merchant.fontFaceName}', sans-serif !important;
      }
    `)
    ) // TODO: * is probably excessive
  }

  document.head.appendChild(styleElement)
}
