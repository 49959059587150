import _capitalize from 'lodash/capitalize'
import _isUndefined from 'lodash/isUndefined'
import _find from 'lodash/find'
import moment from 'moment-timezone'

export const constructNumberOfGuestsString = (number: number | string | undefined, orMore?: boolean, guestString: string = 'guest', capitalize: boolean = false, pluralize: boolean = true) => {
  if (!guestString) {
    guestString = 'guest'
  }

  return `${_isUndefined(number) ? '' : number}${orMore ? '+' : ''}${_isUndefined(number) ? '' : ' '}${capitalize ? _capitalize(guestString) : guestString}${(_isUndefined(number) || number === 1 || !pluralize) ? '' : 's'}`
}
