
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

import { COLOR_AZURE } from '@/constants/colors'

export default {
  name: 'Loading',
  components: {
    ClipLoader,
  },
  props: {
    loading: Boolean,
    color: String,
    size: {
      type: String,
      default: '2rem',
    },
  },
  computed: {
    loaderColor() {
      return (
        this.color || this.$store.state.api.merchant.primaryColor || COLOR_AZURE
      )
    },
  },
}
