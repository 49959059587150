
import { getHostBaseUrl } from '@/helpers/env'
import querystring from 'querystring'
import axios from 'axios'

const HOST_API_BASE_URL = getHostBaseUrl()

const hostApi = axios.create({
  baseURL: HOST_API_BASE_URL,
  headers: {}
})

export const API_URL_RESERVATIONS_STATUS = 'reservations_widget/closures'

export const constructMerchantReservationWidgetStatusUrl = function (merchantId: string) {
  return `/${API_URL_RESERVATIONS_STATUS}?${querystring.stringify({ merchant_id: merchantId })}`
}

export default hostApi
