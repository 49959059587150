export const getBrowserLocale = () => {
  const [locale] = (
    window.navigator.languages || [
      window.navigator.language ||
      (window.navigator as any).userLanguage ||
      'en-US'
    ])

  return locale
}
