import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/index'
import { datadogRum } from '@datadog/browser-rum'
import VueDOMPurifyHTML from 'vue-dompurify-html'

Vue.use(VueDOMPurifyHTML)
Vue.config.productionTip = false

datadogRum.init({
  applicationId: 'db844ea0-ad1f-43d8-bce4-12712e2f4591',
  clientToken: 'pubb013b4eb58cc0023fa2316b338dd5649',
  site: 'datadoghq.com',
  service: 'guest-reservations',
  env: process.env.NODE_ENV,
  version: process.env.VUE_APP_VERSION,
  sampleRate: 40,
  trackInteractions: true,
  // allow RUM to run when app loaded in an iframe
  useSecureSessionCookie: true,
  useCrossSiteSessionCookie: true,
  /**
   * From datadog support - filter error events of this type which are flooding
   * records and don't give us any value
   */
  beforeSend: event => {
    if (
      event.type === 'error' &&
      event.error.message.includes('Object Not Found Matching Id')
    ) {
      return false
    }
  },
})

datadogRum.startSessionReplayRecording()

new Vue({
  router,
  store,
  render: h => h(Vue.extend(App)),
}).$mount('#app')
