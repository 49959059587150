
import _get from 'lodash/get'

export default {
  name: 'App',
  computed: {
    appStyle() {
      if (!_get(this, '$store.state.api.merchant.backgroundImageUrl')) {
        return {}
      }

      return {
        background: `center center no-repeat fixed ${
          this.$store.state.api.merchant.backgroundColor || '#000'
        }`,
        backgroundImage: `url(${this.$store.state.api.merchant.backgroundImageUrl})`,
        zoom: 1,
        backgroundSize: 'cover',
      }
    },
  },
}
