import _find from 'lodash/find'

export class WaitTimeService {
  static mapWaitTimes(waitTimes: any[], numberOfGuestOptions: number[]): any {
    const waitTimeMap = {}

    numberOfGuestOptions.forEach((numberOfGuests) => {
      const waitTime = _find(waitTimes, waitTime => {
        return waitTime['range_min'] <= numberOfGuests &&
          (waitTime['range_max'] >= numberOfGuests || !waitTime['range_max'])
      }) || {}

      waitTimeMap[numberOfGuests] = {
        min: waitTime['wait_min'],
        max: waitTime['wait_max'],
        quoteToken: waitTime['token']
      }
    })

    return waitTimeMap
  }
}
