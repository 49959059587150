import { appendMerchantStyles } from '@/helpers/css'

export const loadMerchantWidgetPresets = (merchant: any) => {
  if (merchant.favIconUrl) {
    let favicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement
    let appleFavicon = document.querySelector("link[rel~='apple-touch-icon']") as HTMLLinkElement
    favicon.href = merchant.favIconUrl
    appleFavicon.href = merchant.favIconUrl
  }

  if (merchant.name) {
    let title = `${merchant.name} ${
      merchant.reservationsEnabled ? 'Reservations' : 'Waitlist'
    }`
    document.getElementById('title').innerHTML = title
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute(
        'content', title)
  }

  appendMerchantStyles(merchant)
}
