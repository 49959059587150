import _some from 'lodash/some'
import _range from 'lodash/range'
import _isArray from 'lodash/isArray'
import _isNumber from 'lodash/isNumber'

import moment, { Moment } from 'moment-timezone'

import { getBrowserLocale } from './localization'

interface ReservationDate {
  label: string
  value: Moment
  available: boolean
  passed: boolean
}
type DateMap = Record<number, Record<number, Record<number, ReservationDate>>>

export const DAYS_OF_THE_WEEK_MAP = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
}

export const daysInMonth = (year: number, monthIndex: number) => {
  return moment([year, monthIndex]).daysInMonth()
}

export const formatDateLongWithYear = (date: Moment) => {
  if (!date) {
    return
  }

  return date.format('MMMM D, YYYY')
}

export const browserTimeZoneDiffersFromMerchant = () => {
  return moment().tz(moment.tz.guess()).format('z') !== moment().format('z')
}

export const formatDateLongWithWeekday = (date: Moment) => {
  if (!date) {
    return
  }

  return date.format('dddd, MMMM Do')
}

export const formatTimeValues = (hours: number, minutes: number) => {
  const time = new Date()
  time.setHours(hours)
  time.setMinutes(minutes)

  return time.toLocaleTimeString(getBrowserLocale(), {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
  })
}
export const formatTime = (time: Moment) => time.format('hh:mm A')

const TEMP_YEAR_RANGE = 2 // TODO: from server?
export function generateAvailabilityDateMap(
  stubbed: boolean = false,
  numberOfDaysSelectableIntoFuture: number,
  reservationAllowedDateRange: Array<Array<string>>
): DateMap {
  const currentYear = moment().get('year')
  const years = _range(currentYear, currentYear + TEMP_YEAR_RANGE)
  const monthIndices = _range(0, 12)

  const dateMap = {}

  years.forEach(year => {
    if (!dateMap[year]) {
      dateMap[year] = {}
    }

    const today = moment()

    let dayLimit
    if (_isNumber(numberOfDaysSelectableIntoFuture)) {
      dayLimit = moment().add(numberOfDaysSelectableIntoFuture, 'days')
    }

    return monthIndices.forEach(monthIndex => {
      if (!dateMap[year][monthIndex]) {
        dateMap[year][monthIndex] = {}
      }

      _range(1, daysInMonth(year, monthIndex) + 1).forEach(day => {
        const value = moment([year, monthIndex, day, 0, 0, 0])

        dateMap[year][monthIndex][day] = {
          available: stubbed ? Math.random() >= 0.5 : true,
          value,
          dayNumber: day,
          day: DAYS_OF_THE_WEEK_MAP[day % 7],
          today: value.isSame(today, 'day'),
          passed:
            value.isBefore(today, 'day') ||
            (dayLimit && value.isAfter(dayLimit, 'day')) ||
            !withinDateRange(value, reservationAllowedDateRange),
          label: formatDateLongWithYear(value),
        }
      })
    })
  })

  return dateMap
}

export const withinDateRange = (
  date: Moment,
  dateRangeArray: Array<string | Array<string>>
): boolean => {
  if (!dateRangeArray || !dateRangeArray.length) {
    return true
  }

  if (_isArray(dateRangeArray[0])) {
    return _some(dateRangeArray, (dateRange: Array<string>): boolean =>
      withinDateRange(date, dateRange)
    )
  }
  const startDate: Moment = moment(dateRangeArray[0] as string, 'MM/DD/YYYY')
  const endDate: Moment = moment(dateRangeArray[1] as string, 'MM/DD/YYYY')

  return (
    date.isSameOrAfter(startDate, 'day') && date.isSameOrBefore(endDate, 'day')
  )
}

export const sortTimeOptionsRange = (
  timeOptionsRange: Array<Array<number>>
) => {
  return [...timeOptionsRange].sort((AhrsMinsTimeArray, BhrsMinsTimeArray) => {
    return (
      convertTimeArrayTo24HrFormat(AhrsMinsTimeArray) -
      convertTimeArrayTo24HrFormat(BhrsMinsTimeArray)
    )
  })
}

export const convertTimeArrayTo24HrFormat = (timeArray: Array<number>): any => {
  return timeArray.map(timeUnit => forceTwoDigitTimeFormat(timeUnit)).join('')
}

function forceTwoDigitTimeFormat(timeUnit) {
  let formatedTimeUnit = timeUnit.toString()
  if (formatedTimeUnit.length === 1) {
    formatedTimeUnit = '0' + formatedTimeUnit
  }
  return formatedTimeUnit
}

export function getDateFromMap({ date, map }: { date: Moment; map: DateMap }) {
  const foundDate =
    map[date.get('year')]?.[date.get('month')]?.[date.get('date')]
  return foundDate
}
