var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal",style:(_vm.modalStyle)},[(_vm.merchant && _vm.merchant.transparentLogo)?_c('WiseImage',{staticClass:"modal__corner-logo",style:({
      maxHeight: `${
        _vm.merchant.appLogoHeight ? `${_vm.merchant.appLogoHeight}px` : ''
      }`,
      maxWidth: `${
        _vm.merchant.appLogoWidth ? `${_vm.merchant.appLogoWidth}px` : ''
      }`,
    }),attrs:{"src":_vm.merchant.transparentLogo,"alt":_vm.merchant.name}}):_vm._e(),_c('div',{staticClass:"modal__inner"},[(_vm.fromIFrame)?_c('a',{staticClass:"modal__icon-wrapper modal__icon-wrapper--right",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.closeWindow}},[_c('i',{staticClass:"icon icon--x modal__icon modal__icon--x",class:{ 'modal__icon--white': _vm.whiteModalIcon }})]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"modal__inner__loading-spinner"},[_c('Loading',{attrs:{"loading":_vm.loading}})],1):_vm._e(),(!_vm.loading)?_c('div',[_c('router-view')],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }