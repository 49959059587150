
import { mapState, mapGetters } from 'vuex'

import WiseImage from '@/components/WiseImage.jsx'

import {
  ROUTE_PARAM_NAME_MERCHANT_SLUG,
  ROUTE_URL_CONFIRM,
  ROUTE_URL_NOT_FOUND,
  ROUTE_NAME_NEARBY_MERCHANT_SEARCH,
} from '@/router'

import Loading from '@/components/Loading.vue'
import { PROD, getAPIBaseUrl } from '@/helpers/env'
import { getFormType } from '@/helpers/formsApi'

const SLUG_ROUTE_QUERY_PARAM = 'g'

const fetchMerchantAndRedirectOnFailure = async function({
  merchantSlug,
  formSlug,
}) {
  const IS_LOCATION_SEARCH_ROUTE =
    this.$route.path.includes(ROUTE_NAME_NEARBY_MERCHANT_SEARCH) ||
    window.location.host.includes(ROUTE_NAME_NEARBY_MERCHANT_SEARCH)

  const formType = getFormType(this)

  if (!merchantSlug && !IS_LOCATION_SEARCH_ROUTE) {
    this.$router.push({ name: ROUTE_URL_NOT_FOUND })
  }

  try {
    if (IS_LOCATION_SEARCH_ROUTE) {
      if (!formSlug) {
        this.$router.push({ name: ROUTE_URL_NOT_FOUND })
      }

      const { locationSearchEnabled } = await this.$store.dispatch('api/getFormAttributes', {
        formType,
        formSlug,
      })

      if (!locationSearchEnabled) {
        this.$router.push({ name: ROUTE_URL_NOT_FOUND })
      }
    }

    if (merchantSlug) {
      await this.$store.dispatch('api/getMerchant', {
        merchantSlug,
        formType,
        formSlug,
      })
    }

    setGtmCode(this.programId)
  } catch (error) {
    console.log(error)
    this.$router.push({ name: ROUTE_URL_NOT_FOUND })
  }
}

const setGtmCode = (programId) => {
  if (programId) {
    const gtm = document.createElement('script')
    gtm.setAttribute(
      'src',
      `${getAPIBaseUrl()}/merchants/external/programs/${programId}/gtm`
    )
    document.head.appendChild(gtm)
  }
}

export default {
  name: 'Modal',
  components: {
    Loading,
    WiseImage,
  },
  methods: {
    closeWindow: function() {
      window.top.postMessage('closeIFrame', '*')
    },
  },
  beforeCreate: async function() {
    const formSlug = this.$route.query[SLUG_ROUTE_QUERY_PARAM]
    const merchantSlug = this.$route.params[ROUTE_PARAM_NAME_MERCHANT_SLUG]
    fetchMerchantAndRedirectOnFailure.bind(this)({
      merchantSlug,
      formSlug,
    })
  },
  async beforeRouteUpdate(to, from, next) {
    if (
      to.params[ROUTE_PARAM_NAME_MERCHANT_SLUG] !==
      from.params[ROUTE_PARAM_NAME_MERCHANT_SLUG]
    ) {
      const formSlug = to.query[SLUG_ROUTE_QUERY_PARAM]
      const merchantSlug = to.params[ROUTE_PARAM_NAME_MERCHANT_SLUG]
      await fetchMerchantAndRedirectOnFailure.bind(this)({
        merchantSlug,
        formSlug,
      })
    }

    next()
  },
  computed: {
    ...mapState({
      merchant: state => (state as any).api.merchant,
    }),
    ...mapGetters({
      programId: 'api/programId',
      merchantLoading: 'api/merchantLoading',
      reservationLoadingInitially: 'api/reservationLoadingInitially',
    }),
    modalStyle() {
      return {
        background: `rgba${this.$store.state.api.merchant.overlay ||
          'linear-gradient(180deg, rgba(0, 0, 0, 0.096) 0%, rgba(0, 0, 0, 0.6) 86.46%);'}`,
      }
    },
    loading: {
      get() {
        return this.merchantLoading || this.reservationLoadingInitially
      },
    },
    whiteModalIcon: {
      get() {
        return this.$route.name === ROUTE_URL_CONFIRM
      },
    },
    fromIFrame() {
      return false // TODO: make this work in iframes
    },
  },
}
