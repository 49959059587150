import { getFormsBaseUrl } from '@/helpers/env'
import axios from 'axios'
import querystring from 'querystring'

const FORMS_API_BASE_URL = getFormsBaseUrl()

const formsApi = axios.create({
  baseURL: FORMS_API_BASE_URL,
  headers: {},
})

export const API_URL_FORMS_PRESETS = 'presets'

export const constructFormAttributesUrl = function(
  merchantSlug: string,
  formType: string,
  formSlug: string | undefined
) {
  return `/${API_URL_FORMS_PRESETS}?${querystring.stringify({
    merchant_slug: merchantSlug,
    form_slug: formSlug,
    form_type: formType,
  })}`
}

export const getFormType = context => {
  return window.location.host.indexOf('waitlist') > -1 ||
    context.$route.path.indexOf('waitlist') > -1
    ? 'waitlist'
    : 'reservations'
}

export default formsApi
