import Vue from 'vue'
import Router from 'vue-router'

import Modal from '@/components/Modal.vue'

export const ROUTE_PARAM_NAME_PROGRAM_SLUG = 'programId'
export const ROUTE_PARAM_NAME_MERCHANT_SLUG = 'merchantId'
export const ROUTE_PARAM_RESERVATION_ID = 'reservationId'

export const ROUTE_URL_NOT_FOUND = '404'
export const ROUTE_NAME_RESERVATIONS = 'reservations'
export const ROUTE_URL_RESERVATIONS = ''
export const ROUTE_URL_REVIEW = 'review'
export const ROUTE_URL_CONFIRM = 'confirm'
export const ROUTE_URL_MODIFY = 'modify'
export const ROUTE_NAME_MODIFY_REVIEW = `${ROUTE_URL_MODIFY}-${ROUTE_URL_REVIEW}`
export const ROUTE_URL_CANCEL = 'cancel'
export const ROUTE_URL_WAITLIST = 'waitlist'
export const ROUTE_URL_SURVEY = 'survey'
export const ROUTE_NAME_MODIFY_WAITLIST = `${ROUTE_URL_MODIFY}-${ROUTE_URL_WAITLIST}`
export const ROUTE_NAME_NEARBY_MERCHANT_SEARCH = 'location-search'
export const ROUTE_NAME_NEARBY_MERCHANT_SEARCH_WAITLIST =
  'location-search-watlist'
export const SLUG_ROUTE_QUERY_PARAM = 'g'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      component: () => import('@/views/NotFound.vue'),
    },
    {
      path: `/location-search`,
      component: Modal,
      children: [
        {
          path: ``,
          name: ROUTE_NAME_NEARBY_MERCHANT_SEARCH,
          component: () => import('@/views/ProgramNearbyMerchantSearch.vue'),
        },
        {
          path: ROUTE_URL_WAITLIST,
          name: ROUTE_NAME_NEARBY_MERCHANT_SEARCH_WAITLIST,
          component: () => import('@/views/ProgramNearbyMerchantSearch.vue'),
        },
        {
          path: ROUTE_NAME_RESERVATIONS,
          name: 'ProgramNearbyMerchantSearch',
          component: () => import('@/views/ProgramNearbyMerchantSearch.vue'),
        },
      ],
    },
    {
      path: `/:${ROUTE_PARAM_NAME_MERCHANT_SLUG}`,
      component: Modal,
      children: [
        {
          path: ROUTE_URL_RESERVATIONS,
          alias: ROUTE_NAME_RESERVATIONS,
          name: ROUTE_NAME_RESERVATIONS,
          component: () => import('@/views/Reservations.vue'),
        },
        {
          path: ROUTE_URL_SURVEY,
          name: ROUTE_URL_SURVEY,
          component: () => import('@/views/PartySurvey.vue'),
        },
        {
          path: ROUTE_URL_REVIEW,
          name: ROUTE_URL_REVIEW,
          // @ts-ignore
          component: () => import('@/views/ReviewReservation.vue'),
        },
        {
          path: ROUTE_URL_WAITLIST,
          name: ROUTE_URL_WAITLIST,
          component: () => import('@/views/JoinWaitlist.vue'),
        },
        {
          path: ROUTE_URL_NOT_FOUND,
          name: ROUTE_URL_NOT_FOUND,
          component: () => import('@/views/NotFound.vue'),
        },
        {
          path: `:${ROUTE_PARAM_RESERVATION_ID}`,
          component: () => import('@/views/ExistingReservation.vue'),
          children: [
            {
              path: '',
              redirect: to => {
                return `/${to.params[ROUTE_PARAM_NAME_MERCHANT_SLUG]}/${to.params[ROUTE_PARAM_RESERVATION_ID]}/${ROUTE_URL_CONFIRM}`
              },
            },
            {
              path: ROUTE_URL_MODIFY,
              name: ROUTE_URL_MODIFY,
              component: () => import('@/views/ModifyReservation.vue'),
            },
            {
              path: ROUTE_URL_REVIEW,
              name: ROUTE_NAME_MODIFY_REVIEW,
              // @ts-ignore
              component: () => import('@/views/ReviewReservation.vue'),
            },
            {
              path: ROUTE_URL_CONFIRM,
              name: ROUTE_URL_CONFIRM,
              component: () => import('@/views/ConfirmReservation.vue'),
            },
            {
              path: ROUTE_URL_CANCEL,
              name: ROUTE_URL_CANCEL,
              component: () => import('@/views/CancelReservation.vue'),
            },
            {
              path: ROUTE_URL_WAITLIST,
              name: ROUTE_NAME_MODIFY_WAITLIST,
              component: () => import('@/views/JoinWaitlist.vue'),
            },
            {
              path: '*',
              redirect: to => {
                return `/${to.params[ROUTE_PARAM_NAME_MERCHANT_SLUG]}/${to.params[ROUTE_PARAM_RESERVATION_ID]}/${ROUTE_URL_CONFIRM}`
              },
            },
          ],
        },
      ],
    },
  ],
})

let modal
router.afterEach((to, from) => {
  if (to.name !== from.name) {
    if (!modal) {
      modal = document.querySelector('.modal')
    }

    if (modal) {
      setTimeout(() => (modal.scrollTop = 0))
    }
  }
})

export default router
