import Vue from 'vue'
import * as LDClient from 'launchdarkly-js-client-sdk'
import { getLaunchDarklySdkKey } from '@/helpers/env'

export const MUTATION_UPDATE_FEATURE_FLAG = 'MUTATION_UPDATE_FEATURE_FLAG'
export const MUTATION_START_SYNCING = 'MUTATION_START_SYNCING'

const LAUNCH_DARKLY_HOST_ENABLE_SHARED_EXPERIENCES =
  'host-app-enable-shared-experiences'

const FEATURE_FLAGS = [
  {
    key: LAUNCH_DARKLY_HOST_ENABLE_SHARED_EXPERIENCES,
    defaultValue: false,
  },
]

let ldclient
const state = {
  flags: {},
  startedSyncing: false,
}

export default {
  namespaced: true,
  state,
  mutations: {
    [MUTATION_UPDATE_FEATURE_FLAG]: (state, { key, value }) => {
      Vue.set(state.flags, key, value)
    },
    [MUTATION_START_SYNCING]: state => {
      state.startedSyncing = true
    },
  },
  actions: {
    startSyncingFeatureFlags: ({ dispatch }) => {
      FEATURE_FLAGS.forEach(flag => dispatch('syncFeatureFlag', flag))
    },
    syncFeatureFlag: async (
      { dispatch, commit },
      { key, defaultValue = false }
    ) => {
      if (!ldclient) {
        await dispatch('identifyUser')
      }

      commit(MUTATION_UPDATE_FEATURE_FLAG, {
        key,
        value: ldclient.variation(key, defaultValue),
      })

      ldclient.on(`change:${key}`, function (value, previous) {
        commit(MUTATION_UPDATE_FEATURE_FLAG, {
          key,
          value,
        })
      })
    },
    identifyUser: async ({ state, commit, dispatch }, merchantId) => {
      return new Promise<void>(resolve => {
        const startSyncingAndResolve = () => {
          if (!state.startedSyncing) {
            commit(MUTATION_START_SYNCING)
            dispatch('startSyncingFeatureFlags')
          }
          resolve()
        }
        const user = {
          key: `host-widget-${merchantId}`,
          custom: {
            merchantId: Number(merchantId),
          },
        }
        if (!ldclient) {
          ldclient = LDClient.initialize(getLaunchDarklySdkKey(), user)
          ldclient.on('ready', startSyncingAndResolve)
        } else {
          ldclient.identify(user, null, startSyncingAndResolve)
        }
      })
    },
  },
  getters: {
    featureFlagEnabled: state => key => {
      return state.flags[key]
    },
    sharedExperiencesEnabled: (state, getters) => {
      return getters.featureFlagEnabled(
        LAUNCH_DARKLY_HOST_ENABLE_SHARED_EXPERIENCES
      )
    },
  },
}
