import Vue from 'vue'

export default {
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    this.$el.addEventListener('load', this.doneLoading)
  },
  methods: {
    doneLoading() {
      this.loading = false
      this.$el.removeEventListener('load', this.doneLoading)
    },
  },
  render(h) {
    return (
      <img
        {...{ attrs: this.$attrs }}
        class={{ image: true, 'image--loading': this.$data.loading }}
      />
    )
  },
}
