import _isUndefined from 'lodash/isUndefined'

export default {
  namespaced: true,
  state: {
    intervalId: undefined
  },
  mutations: {
    update (state, updates: object = {}) {
      Object.assign(state, updates)

      this.dispatch('api/updateWaitTimes')
    }
  },
  actions: {
    async start ({ commit }) {
      const intervalId = setInterval(commit.bind(this, 'update'), 60000)

      commit('update', { intervalId })
    },
    stop ({ commit, state }) {
      if (!_isUndefined(state.intervalId)) {
        clearInterval(state.intervalId)
      }

      commit('update', { intervalId: undefined })
    }
  }
}
