import _isUndefined from 'lodash/isUndefined'

import moment from 'moment-timezone'

const API_URL_TIMER = 'timers'

const TEMP_TIMER_ID = 'fjknwekjfnwe124124'

const TEMP_API_RESPONSE = {
  code: 200,
  messages: [
    'Timer created'
  ],
  // errors: [],
  data: {
    id: TEMP_TIMER_ID,
    endTime: undefined
  }
}

const tempGenerateTimerApiResponse = () => {
  const endTime = moment()
  endTime.add(TIMER_LENGTH_MINUTES_DEFAULT, 'minute')

  return {
    ...TEMP_API_RESPONSE,
    data: {
      ...TEMP_API_RESPONSE.data,
      endTime
    }
  }
}

const TIMER_LENGTH_MINUTES_DEFAULT = 3

export default {
  namespaced: true,
  state: {
    endTime: undefined,
    intervalId: undefined,
    timeToBook: undefined,
    postPromise: undefined,
    id: undefined
  },
  getters: {
    loading: state => !!state.postPromise,
    expired: state => !_isUndefined(state.timeToBook) && state.timeToBook <= 0,
    timeToBookFormatted (state, getters) {
      if (getters.expired) {
        return '0:00'
      }

      const minutes: number = Math.floor(state.timeToBook / 60000)
      const seconds = ((state.timeToBook % 60000) / 1000).toFixed(0)

      return Number(seconds) === 60
        ? (minutes + 1) + ':00'
        : minutes + ':' + (Number(seconds) < 10 ? '0' : '') + seconds
    }
  },
  mutations: {
    update (state, updates: object = {}) {
      Object.assign(state, updates)

      if (!_isUndefined(state.endTime)) {
        state.timeToBook = state.endTime.diff(moment())
      }
    }
  },
  actions: {
    async start ({ commit }) {
      const intervalId = setInterval(commit.bind(this, 'update'), 1000)
      // const postPromise = api.post(API_URL_TIMER, {
      //   data: {
      //     reservation: this.state.api.reservation
      //   }
      // })

      // commit('update', { postPromise })

      // const { data } = await postPromise as any

      // commit('update', { postPromise: undefined, intervalId, ...data })
      commit('update', { postPromise: undefined, intervalId, ...tempGenerateTimerApiResponse().data })
    },
    stop ({ commit, state }) {
      if (!_isUndefined(state.intervalId)) {
        clearInterval(state.intervalId)
      }
      commit('update', { intervalId: undefined, endTime: undefined, timeToBook: undefined })
    }
  }
}
