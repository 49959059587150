import { getBrowserLocale } from '@/helpers/localization'

/**
 * @param  {number} price
 * @param  {string=getBrowserLocale()} locale
 * @param  {string='USD'} currency
 */
export const formatPriceString = (price: number, locale: string = getBrowserLocale(), currency: string = 'USD') => {
  return price.toLocaleString(locale, {
    style: 'currency',
    currency
  })
}
