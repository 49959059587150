import api, {
  constructMerchantInventorySharedExperienceUrl,
  constructSharedExperienceReservationUrl,
  constructMerchantInventoryUrl,
  constructMerchantSlugUrl,
  constructMerchantUrl,
  constructReservationUrl,
  constructSectionalWaitTimeUrl,
  constructWaitTimeUrl,
  convertMilitaryTimeStringToHourMinuteArray,
  formatSectionWaitTime,
  FREE_FORM_SPECIAL_OCCASION_VALUE,
  mapMerchantInventory,
  mapMerchantToNumberOfGuestOptions,
  mapSelectableTimeRangesToTimeOptions,
  NUMBER_OF_GUESTS_MIN_DEFAULT,
  parseSectionalWaitTimes,
} from '@/helpers/api'
import {
  formatDateLongWithYear,
  formatTime,
  formatTimeValues,
  generateAvailabilityDateMap,
  withinDateRange,
} from '@/helpers/dates'
import formsApi, { constructFormAttributesUrl } from '@/helpers/formsApi'
import hostApi, {
  constructMerchantReservationWidgetStatusUrl,
} from '@/helpers/hostApi'
import logger from '@/helpers/logger'
import { constructNumberOfGuestsString } from '@/helpers/reservations'
import { WaitTimeService } from '@/helpers/WaitTimeService'
import { loadMerchantWidgetPresets } from '@/helpers/widgetPresets'
import { AxiosResponse } from 'axios'
import _capitalize from 'lodash/capitalize'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _flatten from 'lodash/flatten'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _isNull from 'lodash/isNull'
import _isNumber from 'lodash/isNumber'
import _isUndefined from 'lodash/isUndefined'
import _mapKeys from 'lodash/mapKeys'
import _range from 'lodash/range'
import _snakeCase from 'lodash/snakeCase'
import * as Mailcheck from 'mailcheck'
import moment from 'moment-timezone'
import isEmail from 'validator/lib/isEmail'
import featureFlags from './featureFlags'

interface CustomFieldsUiFormat {
  specialOccasion?: any
  specialOccasionFreeForm?: any
  specialRequest?: any
}

interface CustomFieldsDbFormat {
  special_occasion?: string[]
  special_occasion_free_form?: string[]
  special_request?: string[]
}

const fetchMerchant = merchantUrl => {
  return api.get(merchantUrl, {
    transformResponse: [
      function (data) {
        const merchants = JSON.parse(data).merchants

        const [merchant] = (merchants || []).map(merchant => {
          if (merchant.timezone) {
            moment.tz.setDefault(merchant.timezone)
          }
          return mapMerchantAttributes(merchant)
        })
        return merchant
      },
    ],
  })
}

const formatCustomFieldsForSave = (
  customFields: CustomFieldsUiFormat
): CustomFieldsDbFormat => {
  const fields_to_save = {}

  Object.entries(customFields).forEach(([key, value]) => {
    if (!_isEmpty(value)) {
      fields_to_save[_snakeCase(key)] = value
    }
  })

  return fields_to_save
}

const formatCustomFieldsForUi = (
  custom_fields: CustomFieldsDbFormat
): CustomFieldsUiFormat => {
  if (!custom_fields || !Object.keys(custom_fields).length) {
    return {}
  }

  let specialOccasion =
    custom_fields.special_occasion?.length && custom_fields.special_occasion[0]
  const specialOccasionFreeForm =
    (custom_fields.special_occasion_free_form?.length &&
      custom_fields.special_occasion_free_form[0]) ||
    null

  if (specialOccasionFreeForm?.length) {
    specialOccasion = _capitalize(FREE_FORM_SPECIAL_OCCASION_VALUE)
  }

  return {
    specialRequest: custom_fields.special_request || null,
    specialOccasion,
    specialOccasionFreeForm,
  }
}

const mapMerchantAttributes = merchant => {
  return {
    id: merchant.merchant_id,
    slug: merchant.slug,
    name: merchant.merchant_name,
    address: merchant.address,
    lat: merchant.lat,
    lon: merchant.lon,
    phone: merchant.phone,
    utcOffsetMins: merchant.utc_offset_mins,
    nearbyProgramMerchants: (merchant.nearby_program_merchants || []).map(
      merchant => {
        return {
          id: merchant.merchant_id,
          slug: merchant.slug,
          name: merchant.merchant_name,
        }
      }
    ),
    hasOtherLocations: !!(merchant.nearby_program_merchants || []).length,
    program: {
      description: merchant.program_description,
      id: merchant.program_id,
      name: merchant.program_name,
    },
    favIconUrl: merchant.favicon_url,
    stripeAccountId: merchant.stripe_account_id,
    disabledSections: merchant.excluded_section_ids || [],
    deprecatedAcceptsSpecialRequests:
      _isUndefined(merchant.accepts_special_requests) ||
      _isNull(merchant.accepts_special_requests)
        ? true
        : Boolean(merchant.accepts_special_requests),
  }
}

const mapFormAttributes = formAttributes => {
  return {
    formId: formAttributes.form_id,
    program: {
      id: formAttributes.program_id,
      merchantIds: formAttributes.form_merchant_ids,
    },
    formSlug: formAttributes.form_slug, // These form attributes should likely exist on a level that isn't the merchant
    reservationSubheader: formAttributes.reservation_subheader,
    image: formAttributes.logo_url,
    transparentLogo: formAttributes.light_logo_url || formAttributes.logo_url,
    appLogoHeight: formAttributes.logo_height,
    appLogoWidth: formAttributes.logo_width,
    maxPartySizes: formAttributes.max_party_sizes,
    backgroundImageUrl: formAttributes.bg_image_url,
    overlay: formAttributes.overlay,
    backgroundColor: formAttributes.bg_color,
    selectableTimeRanges: formAttributes.res_selectable_time_ranges,
    initialTimeSelections: (
      formAttributes.res_initial_time_selection || []
    ).map(convertMilitaryTimeStringToHourMinuteArray),
    reservationsEnabled:
      _isUndefined(formAttributes.res_reservations_enabled) ||
      _isNull(formAttributes.res_reservations_enabled)
        ? true
        : Boolean(Number(formAttributes.res_reservations_enabled)),
    waitlistEnabled:
      _isUndefined(formAttributes.res_waitlist_enabled) ||
      _isNull(formAttributes.res_waitlist_enabled)
        ? true
        : Boolean(Number(formAttributes.res_waitlist_enabled)),
    primaryColor: formAttributes.brand_primary_color,
    dangerColor: formAttributes.res_color_danger, // TODO: add support
    confirmColor: formAttributes.res_color_confirm, // TODO: add support
    fontFaceUrl: formAttributes.font_url,
    fontFaceName: formAttributes.font_name,
    marketingCommunicationShown: Boolean(
      Number(formAttributes.marketing_opt_in)
    ),
    marketingCommunicationAllowedDefault: Boolean(
      Number(formAttributes.marketing_opt_in_checked)
    ),
    marketingCommunicationLabel: formAttributes.marketing_opt_in_text,
    marketingSMSCommunicationShown: Boolean(
      Number(formAttributes.sms_marketing_opt_in_enabled)
    ),
    marketingSMSCommunicationTitle: formAttributes.sms_marketing_opt_in_title,
    marketingSMSCommunicationDescription:
      formAttributes.sms_marketing_opt_in_description,
    numberOfDaysSelectableIntoFuture: formAttributes.res_future_selectable_days,
    showNearbyRestaurants:
      _isUndefined(formAttributes.show_nearby_restaurants) ||
      _isNull(formAttributes.show_nearby_restaurants)
        ? true
        : Boolean(Number(formAttributes.show_nearby_restaurants)),
    reservationAllowedDateRange: formAttributes.special_reso_date_range,
    minimumWaitTime: formAttributes.res_wait_time_min,
    minimumWaitTimeMessageHTML: formAttributes.res_wait_time_min_message,
    maxPartySizeMessage: formAttributes.max_party_size_message,
    otherLocationsButtonTitle: formAttributes.res_other_locations_button_title,
    footerText: formAttributes.footer_text,
    waitlistSubheader: formAttributes.res_waitlist_subheader,
    reviewPageCompleteButtonTextWaitlist:
      formAttributes.res_review_page_complete_button_text_waitlist,
    reviewPageCompleteButtonTextReservations:
      formAttributes.res_review_page_complete_button_text_reservations,
    reviewPageSubtitleWaitlist:
      formAttributes.res_review_page_subtitle_waitlist,
    reviewPageSubtitleReservations:
      formAttributes.res_review_page_subtitle_reservations,
    termsAndConditionsTitle: formAttributes.res_paid_terms_conditions_title,
    termsAndConditions: formAttributes.res_paid_terms_conditions,
    guestAlternativeText: formAttributes.res_guest_alternative_text,
    minPartySizes: formAttributes.res_min_party_sizes,
    paidCancellationDisclaimer: formAttributes.res_paid_cancellation_disclaimer,
    reviewPageTitleWaitlist: formAttributes.res_review_page_title_waitlist,
    reviewPageTitleReservations:
      formAttributes.res_review_page_title_reservations,
    reviewPageGuestInfoTitle: formAttributes.res_review_page_title_guest_info,
    canceledPageTitle: formAttributes.res_canceled_page_title,
    canceledPageSubtitle: formAttributes.res_canceled_page_subtitle,
    waitlistMaxPartySizes: formAttributes.res_max_party_sizes_waitlist,
    waitlistMinPartySizes: formAttributes.res_min_party_sizes_waitlist,
    reservationTypePriceCopy: formAttributes.res_type_price_copy, // TODO: this should be on reservation types instead
    autoSearch:
      _isUndefined(formAttributes.initial_search) ||
      _isNull(formAttributes.initial_search)
        ? true
        : Boolean(Number(formAttributes.initial_search)),
    reservationSearchPrompt: formAttributes.res_search_prompt,
    autoSearchNearby:
      _isUndefined(formAttributes.nearby_search) ||
      _isNull(formAttributes.nearby_search)
        ? false
        : Boolean(Number(formAttributes.nearby_search)),
    showNearbyRestaurantsMessage:
      formAttributes.show_nearby_restaurants_message,
    reservationDescription: formAttributes.res_times_description,
    nearbyRestaurantsUnavailable: formAttributes.res_nearby_unavailable_message,
    showNearbyResType:
      _isUndefined(formAttributes.show_nearby_res_type) ||
      _isNull(formAttributes.show_nearby_res_type)
        ? false
        : Boolean(Number(formAttributes.show_nearby_res_type)),
    locationSearchEnabled:
      _isUndefined(formAttributes.location_search_enabled) ||
      _isNull(formAttributes.location_search_enabled)
        ? false
        : Boolean(Number(formAttributes.location_search_enabled)),
    otherLocationsResTypeButtonTitle:
      formAttributes.other_location_res_type_button_title,
    hideReservationPrice:
      _isUndefined(formAttributes.hide_reservation_price) ||
      _isNull(formAttributes.hide_reservation_price)
        ? false
        : Boolean(Number(formAttributes.hide_reservation_price)),
    showMobileSubheader:
      _isUndefined(formAttributes.show_mobile_subheader) ||
      _isNull(formAttributes.show_mobile_subheader)
        ? false
        : Boolean(Number(formAttributes.show_mobile_subheader)),
    enableSectionalQuoting:
      _isUndefined(formAttributes.enable_sectional_quoting) ||
      _isNull(formAttributes.enable_sectional_quoting)
        ? false
        : Boolean(Number(formAttributes.enable_sectional_quoting)),
    pixelId: formAttributes.facebook_pixel_id,
    hideQuoteTime: Boolean(Number(formAttributes.hide_quote_time)),
    acceptsSpecialRequests:
      formAttributes.special_requests && formAttributes.special_requests.length,
    acceptsSpecialOccasions:
      formAttributes.special_occasions &&
      formAttributes.special_occasions.length,
    specialRequestsList: formAttributes.special_requests,
    specialOccasionsList: formAttributes.special_occasions,
    acceptsSpecialOccasionsFreeForm:
      _isUndefined(formAttributes.special_occasions_free_form) ||
      _isNull(formAttributes.special_occasions_free_form)
        ? false
        : Boolean(Number(formAttributes.special_occasions_free_form)),
    acceptsFreeFormPartyNotes:
      _isUndefined(formAttributes.free_form_notes) ||
      _isNull(formAttributes.free_form_notes)
        ? true
        : Boolean(Number(formAttributes.free_form_notes)),
  }
}

export const STATE_INVENTORY_EMPTY = {
  items: [],
}

const STATE_MERCHANT_DEFAULT = {
  reservationsEnabled: true,
  waitlistEnabled: true,
  locationSearchEnabled: false,
  id: undefined,
  name: undefined,
  address: undefined,
  lat: undefined,
  lon: undefined,
  reservationSubheader: undefined,
  image: undefined,
  phone: undefined,
  waitTimes: undefined,
  maxPartySizes: undefined,
  selectableTimeRanges: undefined,
  utcOffsetMins: undefined,
  nearbyProgramMerchants: undefined,
  hasOtherLocations: undefined,
  backgroundImageUrl: undefined,
  favIconUrl: undefined,
  initialTimeSelections: undefined,
  overlay: undefined,
  backgroundColor: undefined,
  primaryColor: undefined,
  dangerColor: undefined,
  confirmColor: undefined,
  fontFaceUrl: undefined,
  fontFaceName: undefined,
  marketingCommunicationShown: undefined,
  marketingCommunicationAllowedDefault: undefined,
  marketingCommunicationLabel: undefined,
  marketingSMSCommunicationShown: undefined,
  marketingSMSCommunicationTitle: undefined,
  marketingSMSCommunicationDescription: undefined,
  numberOfDaysSelectableIntoFuture: undefined,
  showNearbyRestaurants: undefined,
  reservationAllowedDateRange: undefined,
  acceptsSpecialRequests: undefined,
  deprecatingAcceptsSpecialRequests: undefined,
  acceptsSpecialOccasions: undefined,
  minimumWaitTime: undefined,
  minimumWaitTimeMessageHTML: undefined,
  waitlistClosed: undefined,
  waitlistMessage: undefined,
  waitlistMaxPartySizes: undefined,
  waitlistMinPartySizes: undefined,
  reservationTypePriceCopy: undefined,
  autoSearch: true,
  reservationSearchPrompt: undefined,
  autoSearchNearby: false,
  showNearbyRestaurantsMessage: undefined,
  reservationDescription: undefined,
  nearbyRestaurantsUnavailable: undefined,
  showNearbyResType: false,
  hideReservationPrice: false,
  showMobileSubheader: false,
  enableSectionalQuoting: false,
  disabledSections: undefined,
  pixelId: undefined,
  program: {
    id: undefined,
    name: undefined,
    description: undefined,
    merchantIds: undefined,
  },
}

const RESERVATION_STATE_DEFAULT = {
  id: undefined,
  typeId: undefined,
  merchantId: undefined,
  available: undefined,
  numberOfGuests: undefined,
  time: undefined,
  day: undefined,
  waitlist: undefined,
  customFields: {
    specialRequest: undefined,
    specialOccasion: undefined,
    specialOccasionFreeForm: undefined,
  },
  partyNotes: undefined,
  guest: undefined,
  inventoryItem: undefined,
  partyId: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  deletedAt: undefined,
  marketingCommunicationAllowed: undefined,
  smsMarketingOptIn: false,
  stripeChargeId: undefined,
  party: undefined,
  surveyResponse: undefined,
  surveyResponseSummary: undefined,
}

export default {
  namespaced: true,
  state: {
    dateAvailabilityMap: {},
    resMaxPartySize: undefined,
    resMinPartySize: undefined,
    inventory: { ...STATE_INVENTORY_EMPTY },
    inventoryOther: undefined,
    closed: undefined,
    closureMessage: undefined,
    reservation: { ...RESERVATION_STATE_DEFAULT },
    merchant: { ...STATE_MERCHANT_DEFAULT },
    guest: {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      emailSuggested: undefined,
      phone: undefined,
      phoneValid: undefined,
      countryCode: undefined,
      cCFullName: undefined,
      cCAddressCity: undefined,
      cCAddressCountry: undefined,
      cCAddressLineOne: undefined,
      cCAddressLineTwo: undefined,
      cCAddressState: undefined,
      cCAddressZip: undefined,
      cCComplete: undefined,
      cCToken: undefined,
    },
    getMerchantPromise: undefined,
    getInventoryPromise: undefined,
    getInventoryOtherPromise: undefined,
    saveReservationPromise: undefined,
    getReservationPromise: undefined,
    getFormAttributesPromises: undefined,
    deleteReservationPromise: undefined,
    manualMerchantLoading: undefined,
  },
  getters: {
    reservationCanceled(state) {
      return !!state.reservation.id && !!state.reservation.deletedAt
    },
    reservationValid: state =>
      !!(
        state.reservation.numberOfGuests &&
        state.reservation.day &&
        state.reservation.time
      ) || !!state.reservation.waitlist,
    reservationSaved: state => !!state.reservation.id,
    guestFirstNameValid: state => !!state.guest.firstName,
    guestLastNameValid: state => !!state.guest.lastName,
    guestEmailValid: state => !!state.guest.email && isEmail(state.guest.email),
    guestPhoneValid: state => !!state.guest.phoneValid,
    guestValid: (state, getters) =>
      getters.guestFirstNameValid &&
      getters.guestLastNameValid &&
      getters.guestEmailValid &&
      getters.guestPhoneValid,
    guestCCFullNameValid: state => !!state.guest.cCFullName,
    guestCCAddressCityValid: state => !!state.guest.cCAddressCity,
    guestCCAddressCountryValid: state => !!state.guest.cCAddressCountry,
    guestCCAddressLineOneValid: state => !!state.guest.cCAddressLineOne,
    guestCCAddressStateValid: state => !!state.guest.cCAddressState,
    guestCCAddressZipValid: state => !!state.guest.cCAddressZip,
    guestCCComplete: state => !!state.guest.cCComplete,
    guestCCValid: (state, getters) =>
      getters.guestCCFullNameValid &&
      getters.guestCCAddressZipValid &&
      getters.guestCCComplete,
    inventoryLoading: state => !!state.getInventoryPromise,
    inventoryEmpty: (state, getters) =>
      !getters.inventoryLoading &&
      (!state.inventory.items || !state.inventory.items.length),
    inventoryOtherLoading: state => !!state.getInventoryOtherPromise,
    inventoryOtherEmpty: (state, getters) =>
      !getters.inventoryOtherLoading &&
      state.inventoryOther &&
      state.inventoryOther.items &&
      !state.inventoryOther.items.length,
    merchantLoading: state =>
      !!state.getMerchantPromise ||
      !!state.getFormAttributesPromises ||
      state.manualMerchantLoading,
    reservationSaving: state => !!state.saveReservationPromise,
    reservationLoadingInitially: state =>
      !!state.getReservationPromise && !state.reservation.id,
    reservationCancelling: state => !!state.deleteReservationPromise,
    reservationOtherTimesAvailable: state => !!state.inventory.alternative,
    merchantCurrentWaitTime(state, getters, rootState) {
      if (
        state.merchant.enableSectionalQuoting &&
        !rootState.reservationSearch.numberOfGuests &&
        state.merchant.waitTimes
      ) {
        return {
          min: undefined,
          max: undefined,
          quoteToken: undefined,
        }
      }
      if (
        state.merchant.enableSectionalQuoting &&
        rootState.reservationSearch.numberOfGuests &&
        state.merchant.waitTimes
      ) {
        if (!state.reservation.sectionName) {
          return state.merchant.waitTimes
        }
        return state.merchant.waitTimes[
          state.reservation.section_preferences[0]
        ]
      }
      if (
        !state.merchant.waitTimes ||
        (!state.reservation.numberOfGuests &&
          !rootState.reservationSearch.numberOfGuests) ||
        !state.merchant.waitTimes[
          (
            state.reservation.numberOfGuests ||
            rootState.reservationSearch.numberOfGuests
          ).value
        ]
      ) {
        return {
          min: undefined,
          max: undefined,
          quoteToken: undefined,
        }
      }

      return state.merchant.waitTimes[
        (
          state.reservation.numberOfGuests ||
          rootState.reservationSearch.numberOfGuests
        ).value
      ]
    },
    merchantCurrentWaitTimeFormatted: (state, getters) => {
      if (
        state.merchant.enableSectionalQuoting &&
        getters.merchantCurrentWaitTime
      ) {
        if (!state.reservation.sectionName) {
          return getters.merchantCurrentWaitTime
        }
      }

      if (
        !getters.merchantCurrentWaitTime ||
        !getters.merchantCurrentWaitTime.min ||
        !getters.merchantCurrentWaitTime.max
      ) {
        return
      }

      return formatSectionWaitTime({
        min: getters.merchantCurrentWaitTime.min,
        max: getters.merchantCurrentWaitTime.max,
      })
    },
    numberOfGuestOptions(state, getters, rootState) {
      let guestOptions = []

      if (!state.reservation.waitlist) {
        guestOptions =
          !state.resMinPartySize && !state.resMaxPartySize
            ? [2]
            : _range(state.resMinPartySize, state.resMaxPartySize + 1)
      } else {
        guestOptions = mapMerchantToNumberOfGuestOptions(
          state.merchant.minPartySizes,
          state.merchant.maxPartySizes,
          _get(rootState, 'reservationSearch.day.value')
        )
      }

      return guestOptions
    },
    numberOfGuestOptionsFormatted: (state, getters) => {
      const numberOfGuestOptionsFormatted = getters.numberOfGuestOptions.map(
        number => ({
          label: constructNumberOfGuestsString(
            number,
            false,
            state.merchant.guestAlternativeText
          ),
          value: number,
        })
      )

      if (state.merchant.maxPartySizeMessage) {
        const value =
          (getters.numberOfGuestOptions[
            getters.numberOfGuestOptions.length - 1
          ] ||
            getters.numberOfGuestOptions[0] ||
            NUMBER_OF_GUESTS_MIN_DEFAULT) + 1

        numberOfGuestOptionsFormatted.push({
          label: constructNumberOfGuestsString(
            value,
            true,
            state.merchant.guestAlternativeText
          ),
          value,
        })
      }

      return numberOfGuestOptionsFormatted
    },
    numberOfGuestOptionsWaitlist(state, getters, rootState) {
      const start = new Date()

      const guestOptions = mapMerchantToNumberOfGuestOptions(
        state.merchant.waitlistMinPartySizes || state.merchant.minPartySizes,
        state.merchant.waitlistMaxPartySizes || state.merchant.maxPartySizes,
        _get(rootState, 'reservationSearch.day.value')
      )

      return guestOptions
    },
    numberOfGuestOptionsWaitlistFormatted: (state, getters) => {
      const numberOfGuestOptionsWaitlistFormatted =
        getters.numberOfGuestOptionsWaitlist.map(number => ({
          label: constructNumberOfGuestsString(
            number,
            false,
            state.merchant.guestAlternativeText
          ),
          value: number,
        }))

      if (state.merchant.maxPartySizeMessage) {
        const value =
          (getters.numberOfGuestOptionsWaitlist[
            getters.numberOfGuestOptionsWaitlist.length - 1
          ] ||
            getters.numberOfGuestOptionsWaitlist[0] ||
            NUMBER_OF_GUESTS_MIN_DEFAULT) + 1

        numberOfGuestOptionsWaitlistFormatted.push({
          label: constructNumberOfGuestsString(
            value,
            true,
            state.merchant.guestAlternativeText
          ),
          value,
        })
      }

      return numberOfGuestOptionsWaitlistFormatted
    },
    timeOptionMapPerDay(state, getters, rootState) {
      const timeOptionMap = {}

      _range(7).forEach(day => {
        timeOptionMap[day] = mapSelectableTimeRangesToTimeOptions(
          state.merchant.selectableTimeRanges,
          day
        )
      })

      return timeOptionMap
    },
    timeOptions(state, getters, rootState) {
      const day = _get(rootState, 'reservationSearch.day.value')

      if (!day) {
        return []
      }

      return getters.timeOptionMapPerDay[day.get('day')]
    },
    programId(state) {
      return state.merchant.program.id
    },
    programMerchantIds(state) {
      return state.merchant.program.merchantIds
    },
    timeOptionsFormatted(state, getters, rootState) {
      const day = _get(rootState, 'reservationSearch.day.value')

      if (!day) {
        return []
      }

      const today = moment()
      const currentTimestamp = moment().valueOf()
      const isToday = day.isSame(today, 'day')

      const timeOptions = getters.timeOptions.map(timeValue => {
        return {
          label: formatTimeValues(timeValue[0], timeValue[1]),
          value: timeValue,
        }
      })

      return isToday
        ? _filter(timeOptions, formattedTime => {
            const dateTime = moment(day)
              .set('hour', formattedTime.value[0])
              .set('minute', formattedTime.value[1])

            return currentTimestamp < dateTime.valueOf()
          })
        : timeOptions
    },
    defaultSelectedTimeOptionFormatted(state, getters, rootState) {
      if (!state.merchant.initialTimeSelections) {
        return getters.timeOptionsFormatted[0]
      }

      const date = _get(rootState, 'reservationSearch.day.value', moment())

      return (
        _find(getters.timeOptionsFormatted, timeOption => {
          return _isEqual(
            timeOption.value,
            state.merchant.initialTimeSelections[date.get('day')]
          )
        }) || getters.timeOptionsFormatted[0]
      )
    },
    defaultSelectedDayFormatted(state) {
      const firstAvailableDay = moment()
      while (
        state.dateAvailabilityMap[firstAvailableDay.get('year')] &&
        (!_get(
          state.dateAvailabilityMap,
          `[${firstAvailableDay.get('year')}][${firstAvailableDay.get(
            'month'
          )}][${firstAvailableDay.get('date')}].available`
        ) ||
          !withinDateRange(
            firstAvailableDay,
            state.merchant.reservationAllowedDateRange
          ))
      ) {
        firstAvailableDay.add(1, 'day')
      }

      return _get(
        state.dateAvailabilityMap,
        `[${firstAvailableDay.get('year')}][${firstAvailableDay.get(
          'month'
        )}][${firstAvailableDay.get('date')}].available`
      )
        ? {
            value: firstAvailableDay,
            label: formatDateLongWithYear(firstAvailableDay),
          }
        : undefined
    },
    underMinimumWaitTime(state, getters) {
      return (
        _isNumber(state.merchant.minimumWaitTime) &&
        getters.merchantCurrentWaitTime &&
        getters.merchantCurrentWaitTime.min < state.merchant.minimumWaitTime
      )
    },
    maxPartySizeExceeded(state, getters, rootState) {
      return (
        state.merchant.maxPartySizeMessage &&
        ((rootState.reservationSearch.numberOfGuests || {}).label || '').match(
          /\+/
        )
      )
    },
    waitTimesLoading(state) {
      return !state.merchant.waitTimes
    },
    currentPartySize(state, getters, rootState) {
      return rootState.reservationSearch.numberOfGuests
    },
  },
  mutations: {
    resetMerchant(state) {
      state.merchant = { ...STATE_MERCHANT_DEFAULT }
    },
    updateMerchant(state, merchant) {
      state.merchant = { ...state.merchant, ...merchant }

      state.dateAvailabilityMap = generateAvailabilityDateMap(
        !!state.merchant.stubbed,
        state.merchant.numberOfDaysSelectableIntoFuture,
        state.merchant.reservationAllowedDateRange
      )
    },
    updateManualMerchantLoading(state, loading) {
      state.manualMerchantLoading = loading
    },
    resetReservation(state) {
      state.reservation = RESERVATION_STATE_DEFAULT
    },
    updateReservation(state, reservation) {
      state.reservation = { ...state.reservation, ...reservation }
    },
    updateCustomFields(state, customFields) {
      state.reservation.customFields = {
        ...state.reservation.customFields,
        ...customFields,
      }
    },
    updateGuest(state, guest) {
      state.guest = { ...state.guest, ...guest }
    },
    updateInventory(state, inventory) {
      state.inventory = inventory
    },
    updateInventoryOther(state, inventoryOther) {
      state.inventoryOther = inventoryOther
    },
    updateGetFormAttributesPromises(state, promise) {
      state.getFormAttributesPromises = promise
    },
    updateGetMerchantPromise(state, getMerchantPromise) {
      state.getMerchantPromise = getMerchantPromise
    },
    updateGetInventoryPromise(state, getInventoryPromise) {
      state.getInventoryPromise = getInventoryPromise
    },
    updateGetInventoryOtherPromise(state, getInventoryOtherPromise) {
      state.getInventoryOtherPromise = getInventoryOtherPromise
    },
    updateSaveReservationPromise(state, saveReservationPromise) {
      state.saveReservationPromise = saveReservationPromise
    },
    updateGetReservationPromise(state, getReservationPromise) {
      state.getReservationPromise = getReservationPromise
    },
    updateDeleteReservationPromise(state, deleteReservationPromise) {
      state.deleteReservationPromise = deleteReservationPromise
    },
    updateReservationsWidgetStatus(state, { closed, message }) {
      state.closed = closed
      state.closureMessage = message
    },
    updatePartySizeBoundaries(state, { resMinPartySize, resMaxPartySize }) {
      state.resMinPartySize = resMinPartySize
      state.resMaxPartySize = resMaxPartySize
    },
  },
  actions: {
    resetReservation({ commit }) {
      commit('resetReservation')
    },
    updateReservation({ commit }, reservation) {
      commit('updateReservation', reservation)
    },
    updateGuest({ commit, getters, dispatch }, guest) {
      commit('updateGuest', guest)

      if (guest.email) {
        Mailcheck.run({
          email: guest.email,
          suggested: emailSuggested => {
            const suggestedEmailDomainParts: Array<string> =
              emailSuggested.domain.split('.')

            if (
              guest.email === emailSuggested.full ||
              suggestedEmailDomainParts.length <= 1 ||
              Mailcheck.defaultTopLevelDomains.indexOf(
                suggestedEmailDomainParts[1]
              ) === -1 ||
              (Mailcheck.defaultSecondLevelDomains.indexOf(
                suggestedEmailDomainParts[0]
              ) === -1 &&
                Mailcheck.defaultDomains.indexOf(emailSuggested.domain) === -1)
            ) {
              commit('updateGuest', {
                emailSuggested: null,
              })

              return
            }

            commit('updateGuest', {
              emailSuggested: emailSuggested.full,
            })
          },
          empty: () => {
            commit('updateGuest', {
              emailSuggested: null,
            })
          },
        })
      }
    },
    async getFormAttributes(
      { commit, state },
      { merchantSlug, formType, formSlug }
    ) {
      const getFormAttributesPromises = ['waitlist', 'reservations'].map(
        formType =>
          formsApi.get(
            constructFormAttributesUrl(merchantSlug, formType, formSlug),
            {
              validateStatus: function (status) {
                return status === 200 || status === 404
              },
              transformResponse: [
                function (data) {
                  const json = JSON.parse(data)
                  const form = json.form || {}
                  const presets = json.presets || {}

                  return {
                    form_id: form.id,
                    form_slug: formSlug,
                    active: Boolean(form.active),
                    merchant_id: json.merchant_id,
                    program_id: form.program_id,
                    form_merchant_ids: form.merchant_ids,
                    ...presets,
                  }
                },
              ],
            }
          )
      )

      commit('updateGetFormAttributesPromises', getFormAttributesPromises)
      let waitlistResponse, reservationsResponse

      try {
        ;[waitlistResponse, reservationsResponse] = await Promise.all(
          getFormAttributesPromises
        )
      } catch (error) {
        console.log(error)
        throw error
      }

      commit('updateGetFormAttributesPromises', undefined)

      let data: any = {
        res_reservations_enabled: reservationsResponse.data.active,
        res_waitlist_enabled: waitlistResponse.data.active,
      }

      if (formType === 'waitlist') {
        data = mapFormAttributes({
          ...reservationsResponse.data,
          ...waitlistResponse.data,
          ...data,
        })
      } else {
        data = mapFormAttributes({
          ...waitlistResponse.data,
          ...reservationsResponse.data,
          ...data,
        })
      }

      commit('updateMerchant', {
        ...data,
        id:
          waitlistResponse.data.merchant_id ||
          reservationsResponse.data.merchant_id,
      })

      loadMerchantWidgetPresets(state.merchant)
      return data
    },
    async getMerchant(
      { commit, state, getters, dispatch, rootGetters },
      { merchantSlug, formSlug, formType, merchantId }
    ) {
      try {
        merchantId = merchantId || state.merchant.id
        commit('resetMerchant')

        if (merchantId) {
          commit('updateMerchant', { id: merchantId })
        }

        commit('updateManualMerchantLoading', true)

        await dispatch('getFormAttributes', {
          merchantSlug,
          formType,
          formSlug,
        })

        if (!state.merchant.id) {
          throw new Error('Merchant not found')
        }

        await dispatch('featureFlags/identifyUser', state.merchant.id, {
          root: true,
        })

        const getMerchantFromIdPromise = fetchMerchant(
          constructMerchantUrl(state.merchant.id)
        )
        commit('updateGetMerchantPromise', getMerchantFromIdPromise)

        const merchantResponse = (await getMerchantFromIdPromise) as any

        const data = merchantResponse.data

        if (!data) {
          throw new Error('!data: Merchant not found')
        }

        const statusResponse: AxiosResponse<any> = await hostApi.get(
          constructMerchantReservationWidgetStatusUrl(data.id)
        )
        const { closed, message } = statusResponse.data.closureResponse

        commit('updateReservationsWidgetStatus', { closed, message })

        commit('updateGetMerchantPromise', undefined)
        commit('updateManualMerchantLoading', false)

        commit('updateMerchant', data)
      } catch (error) {
        logger({ error })

        commit('updateGetMerchantPromise', undefined)
        commit('updateGetFormAttributesPromises', undefined)
        commit('updateManualMerchantLoading', false)

        throw error
      }
    },

    async getInventory({ commit, state, rootGetters }, reservationSearch) {
      try {
        if (state.getMerchantPromise) {
          await state.getMerchantPromise
        }

        commit('updateInventory', { ...STATE_INVENTORY_EMPTY })

        if (!state.merchant.id) {
          throw new Error('getInventory: Merchant not found')
        }

        const sharedExperiencesEnabled =
          rootGetters['featureFlags/sharedExperiencesEnabled']

        const getInventoryPromise = sharedExperiencesEnabled
          ? api.get(
              constructMerchantInventorySharedExperienceUrl(
                state.merchant.id,
                !!state.merchant.stubbed,
                reservationSearch
              ),
              {
                transformResponse: [mapMerchantInventory],
              }
            )
          : api.get(
              constructMerchantInventoryUrl(
                state.merchant.id,
                !!state.merchant.stubbed,
                reservationSearch
              ),
              {
                transformResponse: [mapMerchantInventory],
              }
            )

        commit('updateGetInventoryPromise', getInventoryPromise)

        const { data } = (await getInventoryPromise) as any
        commit('updateGetInventoryPromise', undefined)

        commit('updateInventory', data)
        const existingNumberOfGuests =
          (state.reservation.numberOfGuests &&
            state.reservation.numberOfGuests.value) ||
          null

        const resMinPartySize = Math.min(
          ...data.items.map(reservationType => reservationType.minPartySize),
          existingNumberOfGuests || Infinity
        )
        const resMaxPartySize = Math.max(
          ...data.items.map(reservationType => reservationType.maxPartySize),
          existingNumberOfGuests || -1
        )

        commit('updatePartySizeBoundaries', {
          resMinPartySize,
          resMaxPartySize,
        })
      } catch (error) {
        logger({ error })

        commit('updateGetInventoryPromise', undefined) // TODO: use errors!?!
      }
    },
    async getInventoryOther({ commit, state }, reservationSearch) {
      try {
        if (state.getMerchantPromise) {
          await state.getMerchantPromise
        }

        commit('updateInventoryOther', { ...STATE_INVENTORY_EMPTY })

        const getInventoryOtherPromise = Promise.all(
          state.merchant.nearbyProgramMerchants.map(merchant => {
            return api
              .get(
                constructMerchantInventoryUrl(
                  merchant.id,
                  !!state.merchant.stubbed,
                  reservationSearch
                ),
                {
                  transformResponse: [mapMerchantInventory],
                }
              )
              .then(({ data }) => {
                const items = data.items.filter(times => times.items.length)
                return items.map(item => ({
                  ...item,
                  merchant: { ...merchant },
                }))
              })
          })
        )

        commit('updateGetInventoryOtherPromise', getInventoryOtherPromise)

        const inventory = {
          items: _flatten((await getInventoryOtherPromise) as any),
        }

        commit('updateGetInventoryOtherPromise', undefined)

        commit('updateInventoryOther', inventory)
      } catch (error) {
        logger({ error })

        commit('updateGetInventoryOtherPromise', undefined) // TODO: use errors!?!
      }
    },
    clearInventoryOther({ commit }) {
      commit('updateInventoryOther', undefined)
    },
    async saveReservation({ commit, state, rootState, rootGetters }) {
      try {
        const savedReservation = {
          ...state.reservation,
          merchantId: state.merchant.id,
          guest: state.guest,
        }

        const apiMethod = savedReservation.id ? api.put : api.post
        const sharedExperiencesEnabled =
          rootGetters['featureFlags/sharedExperiencesEnabled']
        const url = sharedExperiencesEnabled
          ? constructSharedExperienceReservationUrl(
              savedReservation.id,
              savedReservation.waitlist
            )
          : constructReservationUrl(
              savedReservation.id,
              savedReservation.waitlist
            )

        let reservationDateTime
        if (!savedReservation.waitlist) {
          reservationDateTime = savedReservation.time.reservedTs
        }

        const party = state.reservation.party || {}

        let waitTime = {
          min: undefined,
          max: undefined,
          quoteToken: undefined,
        }

        const source = savedReservation.waitlist
          ? 'waitlist-form'
          : 'reservation-form'

        let new_marketing_opt_in = null

        if (savedReservation.marketingCommunicationAllowed) {
          new_marketing_opt_in = [
            {
              target: 'email',
              opt_in: true,
            },
          ]
        }

        if (savedReservation.smsMarketingOptIn) {
          const smsOptIn = {
            target: 'sms',
            opt_in: true,
          }
          new_marketing_opt_in = new_marketing_opt_in
            ? [...new_marketing_opt_in, smsOptIn]
            : [smsOptIn]
        }

        if (savedReservation.waitlist) {
          waitTime =
            state.merchant.waitTimes[
              (
                state.reservation.numberOfGuests ||
                rootState.reservationSearch.numberOfGuests
              ).value
            ] || {}
        }

        if (savedReservation.section_preferences) {
          waitTime =
            state.merchant.waitTimes[savedReservation.section_preferences[0]]
        }

        const reserved_ts = savedReservation.waitlist
          ? undefined
          : reservationDateTime

        const saveReservationPromise = apiMethod(
          url,
          {
            ...party,
            local_id: savedReservation.id,
            party_local_id: party.party_id,
            merchant_id: savedReservation.merchantId,
            party_size: savedReservation.numberOfGuests.value,
            reserved_ts: reserved_ts,
            expected_arrival_ts: reserved_ts,
            name: `${savedReservation.guest.firstName} ${savedReservation.guest.lastName}`,
            first_name: savedReservation.guest.firstName,
            last_name: savedReservation.guest.lastName,
            email: savedReservation.guest.email,
            phone: savedReservation.guest.phone,
            country_code: savedReservation.guest.countryCode,
            stripe_customer_id: savedReservation.stripeCustomerId,
            form_slug: state.merchant.formSlug,
            form_id: state.merchant.formId,
            notes: savedReservation.partyNotes,
            custom_fields: formatCustomFieldsForSave(
              savedReservation.customFields
            ),
            survey_response: JSON.stringify(savedReservation.surveyResponse),
            created_ts: savedReservation.id
              ? savedReservation.createdAt
              : moment().valueOf(),
            marketing_opt_in: savedReservation.marketingCommunicationAllowed,
            new_marketing_opt_in,
            source,
            reservation_type_id: savedReservation.typeId,
            stripe_token: savedReservation.guest.cCToken,
            wait_min: waitTime.min,
            wait_max: waitTime.max,
            quote_token: waitTime.quoteToken,
            section_preferences: savedReservation.section_preferences,
          },
          {
            transformResponse: [
              function (data) {
                const party = JSON.parse(data).party
                const mappedKeys = _mapKeys(
                  party,
                  (value, key) =>
                    ({
                      party_id: 'partyId',
                      merchant_id: 'merchantId',
                      created_ts: 'createdAt',
                      updated_ts: 'updatedAt',
                      local_id: 'id',
                    }[key])
                )

                return {
                  party,
                  ...mappedKeys,
                }
              },
            ],
          }
        )

        commit('updateSaveReservationPromise', saveReservationPromise)

        const response = (await saveReservationPromise) as any

        commit('updateSaveReservationPromise', undefined)

        commit('updateReservation', { ...savedReservation, ...response.data })
      } catch (error) {
        logger({ error })

        commit('updateSaveReservationPromise', undefined) // TODO: use errors!?!

        throw error // Keep this, used by review reservation page
      }
    },
    async deleteReservation(
      { commit, state, rootGetters },
      id = state.reservation.id
    ) {
      const sharedExperiencesEnabled =
        rootGetters['featureFlags/sharedExperiencesEnabled']
      try {
        const deleteReservationPromise = api.delete(
          sharedExperiencesEnabled
            ? constructSharedExperienceReservationUrl(id)
            : constructReservationUrl(id),
          {
            data: {
              ...(state.reservation.party || {}),
              local_id: id,
            },
            transformResponse: [
              function (data) {
                const party = JSON.parse(data).party

                return {
                  ...state.reservation,
                  deletedAt: party.deleted_ts || undefined,
                }
              },
            ],
          }
        )

        commit('updateDeleteReservationPromise', deleteReservationPromise)

        const { data } = await deleteReservationPromise

        commit('updateDeleteReservationPromise', undefined)

        commit('updateReservation', data)
      } catch (error) {
        logger({ error })

        commit('updateDeleteReservationPromise', undefined) // TODO: use errors!?!
      }
    },
    async getReservation({ commit, state }, id: string) {
      try {
        const getReservationPromise = api.get(constructReservationUrl(id), {
          transformResponse: [
            function (data) {
              const reservation = JSON.parse(data)

              if (reservation.survey_response) {
                reservation.survey_response = JSON.stringify(
                  reservation.survey_response
                )
              }

              const reservationDateTime = moment(
                Number(reservation.reserved_ts)
              )

              const splitName = (reservation.name || '').split(/\s/)
              const firstName = splitName[0]
              const lastName = splitName.slice(1).join(' ')

              return {
                party: reservation,
                id: reservation.local_id,
                partyId: reservation.party_id,
                merchantId: reservation.merchant_id,
                numberOfGuests: {
                  value: reservation.party_size,
                  label: constructNumberOfGuestsString(
                    reservation.party_size,
                    false,
                    state.merchant.guestAlternativeText
                  ),
                },
                guest: {
                  phone: reservation.phone,
                  email: reservation.email,
                  firstName,
                  lastName,
                },
                day: {
                  value: reservationDateTime,
                  label: formatDateLongWithYear(reservationDateTime),
                },
                time: {
                  value: [
                    reservationDateTime.get('hour'),
                    reservationDateTime.get('minute'),
                  ],
                  label: formatTime(reservationDateTime),
                },
                partyNotes: reservation.notes,
                customFields: formatCustomFieldsForUi(
                  reservation.custom_fields
                ),
                createdAt: reservation.created_ts,
                updatedAt: reservation.updated_ts,
                deletedAt: reservation.deleted_ts || undefined,
                waitlist: !reservation.reserved_ts,
                marketingCommunicationAllowed: reservation.marketing_opt_in,
                smsMarketingOptIn: reservation.smsMarketingOptIn,
                stripeChargeId: reservation.stripe_charge_id,
                surveyResponse: JSON.stringify(reservation.survey_response),
              }
            },
          ],
          params: {
            local_id: id,
            merchant_id: state.merchant.id,
          },
        })

        commit('updateGetReservationPromise', getReservationPromise)

        const { data } = (await getReservationPromise) as any

        commit('updateGetReservationPromise', undefined)

        commit('updateReservation', data)

        const { guest } = data

        commit('updateGuest', { ...guest, phoneValid: !!guest.phone })
      } catch (error) {
        logger({ error })

        commit('updateGetReservationPromise', undefined) // TODO: use errors!?!

        throw error
      }
    },
    async updateWaitTimes({ commit, state, getters }) {
      if (state.merchant.stubbed) {
        return
      }
      const partySize = getters.currentPartySize
      let updateWaitTimesPromise

      try {
        if (state.merchant.enableSectionalQuoting) {
          if (!partySize) {
            return
          }
          updateWaitTimesPromise = api.get(
            constructSectionalWaitTimeUrl(state.merchant.id, partySize.value),
            {
              transformResponse: [
                data => {
                  const parsedData = JSON.parse(data)
                  return {
                    waitTimes: parseSectionalWaitTimes(
                      parsedData,
                      state.merchant.disabledSections
                    ),
                    waitlistClosed: parsedData.closed,
                    waitlistMessage: parsedData.message,
                  }
                },
              ],
            }
          )
        } else {
          updateWaitTimesPromise = api.get(
            constructWaitTimeUrl(state.merchant.id),
            {
              transformResponse: [
                data => {
                  const parsedData = JSON.parse(data)
                  return {
                    waitTimes: WaitTimeService.mapWaitTimes(
                      parsedData.waittimes,
                      getters.numberOfGuestOptionsWaitlist ||
                        getters.numberOfGuestOptions
                    ),
                    waitlistClosed: parsedData.closed,
                    waitlistMessage: parsedData.message,
                  }
                },
              ],
            }
          )
        }
        const { data } = (await updateWaitTimesPromise) as any

        commit('updateMerchant', data)
      } catch (error) {
        logger({ error })
      }
    },
  },
}
