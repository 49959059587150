export default {
  namespaced: true,
  state: {
    numberOfGuests: undefined,
    time: undefined,
    day: undefined,
  },
  getters: {
    valid: state => !!(state.numberOfGuests && state.day && state.time),
  },
  mutations: {
    update(state, params) {
      Object.assign(state, params)
    },
  },
  actions: {
    shallowUpdate({ commit }, params) {
      commit('update', params)
    },
    update({ commit, getters, state, rootGetters }, params) {
      commit('update', params)

      this.commit('api/updateInventoryOther', [])
      if (getters.valid) {
        this.dispatch('api/getInventory', state)
      }
    },
  },
}
