import _times from 'lodash/times'
import _range from 'lodash/range'
import _flatten from 'lodash/flatten'
import _constant from 'lodash/constant'
import _isUndefined from 'lodash/isUndefined'

import axios from 'axios'

import moment, { Moment } from 'moment-timezone'

import querystring from 'querystring'
import featureFlags from '@/store/modules/featureFlags'

import { formatPriceString } from '@/helpers/prices'
import { getBaseUrl, getAPIBaseUrl } from '@/helpers/env'
import { sortTimeOptionsRange } from '@/helpers/dates'

const API_BASE_URL = getBaseUrl()

export const NUMBER_OF_GUESTS_MIN_DEFAULT = 1
const NUMBER_OF_GUESTS_MAX_DEFAULT = 10
const FIRST_AVAILABLE = 'First Available'

export const MIN_PARTY_SIZES_DEFAULT = _times(
  7,
  _constant(NUMBER_OF_GUESTS_MIN_DEFAULT)
)
export const MAX_PARTY_SIZES_DEFAULT = _times(
  7,
  _constant(NUMBER_OF_GUESTS_MAX_DEFAULT)
)

export const FREE_FORM_SPECIAL_OCCASION_VALUE = 'other'

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {},
})

export const API_URL_MERCHANTS = 'web/staff/merchants'
export const API_URL_INVENTORY = 'web/reservations/inventory'
export const API_URL_SHARED_EXPERIENCE_INVENTORY =
  'web/shared_experience/inventory'
export const API_URL_SHARED_EXPERIENCE_RESERVATIONS =
  'web/shared_experiences/reservations'
export const API_URL_RESERVATIONS = 'web/reservations'
export const API_URL_RESERVATION = 'web/reservation'
export const API_URL_WAITLIST = 'web/waitlist/party/update'
export const API_URL_WAIT_TIMES = 'web/waitlist/waittime'
export const API_URL_SECTION_WAIT_TIMES = 'web/waittime/quote/sections'

const mapReservationSearchToValues = (reservationSearch: any) => {
  const { numberOfGuests, day, time } = reservationSearch

  const [hours, minutes] = time.value
  const reservationDateTime = moment(day.value)
    .set('hour', hours)
    .set('minute', minutes)

  return [numberOfGuests.value, reservationDateTime.valueOf()]
}

export const fetchNearbyMerchants = ({ programId, address }) => {
  return axios.get(
    `${getAPIBaseUrl()}/merchants/external/programs/${programId}/nearby-merchants`,
    {
      params: {
        address,
      },
    }
  )
}

export const constructMerchantSlugUrl = function (merchantSlug: string) {
  return `/${API_URL_MERCHANTS}?${querystring.stringify({
    merchant_slug: merchantSlug,
    show_nearby_program_merchants: 1,
    show_widget_attributes: 1,
  })}`
}

export const constructMerchantUrl = function (merchantId: string) {
  return `/${API_URL_MERCHANTS}?${querystring.stringify({
    merchant_id: merchantId,
    show_nearby_program_merchants: 1,
    show_widget_attributes: 1,
  })}`
}

export const constructMerchantInventoryUrl = function (
  merchantId: string,
  stubbed: boolean,
  reservationSearch?: any
) {
  const [partySize, reservationDateTime] =
    mapReservationSearchToValues(reservationSearch)

  return `/${API_URL_INVENTORY}?${querystring.stringify({
    merchant_id: merchantId,
    party_size: partySize,
    search_ts: reservationDateTime,
    show_reservation_types: 1,
    limit: 3,
  })}`
}

export const constructMerchantInventorySharedExperienceUrl = function (
  merchantId: string,
  stubbed: boolean,
  reservationSearch?: any
) {
  const [partySize, reservationDateTime] =
    mapReservationSearchToValues(reservationSearch)

  return `/${API_URL_SHARED_EXPERIENCE_INVENTORY}?${querystring.stringify({
    merchant_id: merchantId,
    party_size: partySize,
    search_ts: reservationDateTime,
    show_reservation_types: 1,
    limit: 9,
  })}`
}

export const constructReservationUrl = function (
  id?: string,
  waitlist?: boolean
) {
  if (_isUndefined(id)) {
    if (waitlist) {
      return `/${API_URL_WAITLIST}`
    }
    return `/${API_URL_RESERVATIONS}`
  }
  return `/${API_URL_RESERVATION}`
}

export const constructSharedExperienceReservationUrl = function (
  id?: string,
  waitlist?: boolean
) {
  if (_isUndefined(id)) {
    if (waitlist) {
      return `/${API_URL_WAITLIST}`
    }
    return `/${API_URL_SHARED_EXPERIENCE_RESERVATIONS}`
  }
  return `/${API_URL_SHARED_EXPERIENCE_RESERVATIONS}`
}
export const constructWaitTimeUrl = function (merchantId: string) {
  return `/${API_URL_WAIT_TIMES}?${querystring.stringify({
    merchant_id: merchantId,
  })}`
}

export const constructSectionalWaitTimeUrl = function (
  merchantId: string,
  partySize: number
) {
  return `/${API_URL_SECTION_WAIT_TIMES}?${querystring.stringify({
    merchant_id: merchantId,
    party_size: partySize,
  })}`
}

export const mapMerchantInventory = data => {
  const items = JSON.parse(data).types.map(item => {
    const title = item.reservation_type_name
    const subtitle = item.reservation_type_description
    const maxPartySize = item.max_party_size
    const minPartySize = item.min_party_size
    const message = item.message
    const survey = item.survey_questions
    const isDefault = item.is_default

    let price
    if (item.reservation_price && item.reservation_currency) {
      price = {
        value: item.reservation_price,
        label: formatPriceString(
          item.reservation_price,
          undefined,
          item.reservation_currency
        ),
        // tax: 0.06 // TODO: pull from merchant data?
      }
    }

    return {
      id: item.reservation_type_id,
      title,
      subtitle,
      price,
      maxPartySize,
      minPartySize,
      isFlatFee: item.is_flat_fee,
      currency: item.reservation_currency,
      fees: (item.fees || []).map(fee => ({
        id: fee.id,
        type: fee.type,
        amount: fee.amount,
        priceType: fee.price_type,
        policy: fee.policy,
      })),
      message,
      survey,
      isDefault,
      items: item.times.map(time => {
        const dateTime = moment(Number(time.reserved_ts))
        const typeId = time.reservation_type_id || item.reservation_type_id
        return {
          reservedTs: time.reserved_ts,
          id: `${typeId}-${time.reserved_ts}`,
          label: time.display_time,
          value: [dateTime.get('hour'), dateTime.get('minute')],
          available: !!parseInt(time.is_available),
          typeId,
        }
      }),
    }
  })

  return {
    items,
  }
}

export const parseSectionalWaitTimes = (
  parsedData: object,
  disabledSections: Array<number>
) => {
  const filteredSections = {}
  const minSection = { id: null, wait_min: null }

  for (const id in parsedData) {
    if (
      disabledSections.includes(Number(id)) ||
      Number(id) === 0 ||
      id === 'message'
    ) {
      continue
    }

    if (
      minSection.wait_min === null ||
      parsedData[id].wait_min < minSection.wait_min
    ) {
      minSection.id = id
      minSection.wait_min = parsedData[id].wait_min
    }

    filteredSections[id] = {
      merchant_section_name: parsedData[id].merchant_section_name,
      min: parsedData[id].wait_min,
      max: parsedData[id].wait_max,
    }
  }

  filteredSections[0] = {
    merchant_section_name: FIRST_AVAILABLE,
    min: filteredSections[minSection.id].min,
    max: filteredSections[minSection.id].max,
  }

  return filteredSections
}

export const mapMerchantToNumberOfGuestOptions = (
  minPartySizes: Array<number> = MIN_PARTY_SIZES_DEFAULT,
  maxPartySizes: Array<number> = MAX_PARTY_SIZES_DEFAULT,
  selectedDate: Moment = moment()
) => {
  return _range(
    minPartySizes[selectedDate.get('day')] || NUMBER_OF_GUESTS_MIN_DEFAULT,
    (maxPartySizes[selectedDate.get('day')] || NUMBER_OF_GUESTS_MAX_DEFAULT) + 1
  )
}

export const convertMilitaryTimeStringToHourMinuteArray = (
  militaryTimeString: string
) => {
  return [
    parseInt(militaryTimeString.substring(0, 2)),
    parseInt(militaryTimeString.substring(2)),
  ]
}

const TIME_OPTION_INTERVAL_MINUTES_DEFAULT = 15

export const mapSelectableTimeRangesToTimeOptions = (
  selectableTimeRanges: Array<Array<Array<string>>> = [
    [['0500', '2600']],
    [['0500', '2600']],
    [['0500', '2600']],
    [['0500', '2600']],
    [['0500', '2600']],
    [['0500', '2600']],
    [['0500', '2600']],
  ],
  selectedDay: number
): Array<Array<number>> => {
  const timeRangesForSelectedDate = selectableTimeRanges[selectedDay]

  return _flatten(
    timeRangesForSelectedDate.map(([startTime, endTime]) => {
      const [startHours, startMinutes] =
        convertMilitaryTimeStringToHourMinuteArray(startTime)

      const startDateTime = moment()
        .set('day', selectedDay)
        .set('hour', startHours)
        .set('minute', startMinutes)

      let iteratingDateTime = moment(startDateTime)

      const [endHours, endMinutes] =
        convertMilitaryTimeStringToHourMinuteArray(endTime)

      const endDateTime = moment()
        .set('day', selectedDay)
        .set('hour', endHours)
        .set('minute', endMinutes)

      const timeOptionsForRange = []

      while (iteratingDateTime <= endDateTime) {
        timeOptionsForRange.push([
          startDateTime.get('hour') +
            iteratingDateTime.diff(startDateTime, 'hours'),
          iteratingDateTime.get('minute'),
        ])
        iteratingDateTime = moment(iteratingDateTime)
        iteratingDateTime.add(TIME_OPTION_INTERVAL_MINUTES_DEFAULT, 'minute') // TODO: use a new param in widget attrs?
      }

      return sortTimeOptionsRange(timeOptionsForRange)
    })
  )
}

function pluralize(string: string, count: number) {
  if (count === 1) return string
  return `${string}s`
}
function pluralizeHour(count: number) {
  return `${count} ${pluralize('hour', count)}`
}
function pluralizeMinute(count: number) {
  return `${count} ${pluralize('minute', count)}`
}

export const formatSectionWaitTime = (section: {
  min: number
  max: number
}): string => {
  // Take the floor as we'll use natural language, "1 hour 15 minutes"
  const minHours = Math.floor(section.min / 60)
  const minMinutes = section.min % 60
  const maxHours = Math.floor(section.max / 60)
  const maxMinutes = section.max % 60

  let minHourString = minHours > 0 ? pluralizeHour(minHours) : ''
  let minMinuteString = minMinutes > 0 ? pluralizeMinute(minMinutes) : ''

  if (minHours === 0 && maxHours === 0) {
    // If both times are under an hour, do not add the word "minutes" to the min
    minMinuteString = String(minMinutes)
  }

  if (minMinutes === 0 && maxMinutes === 0) {
    // If both times are on the hour, omit the first "hour"
    minHourString = String(minHours)
  }

  const maxHourString = maxHours > 0 ? pluralizeHour(maxHours) : ''
  const maxMinuteString = maxMinutes > 0 ? pluralizeMinute(maxMinutes) : ''

  const minPortion = `${minHourString} ${minMinuteString}`.trim()
  const maxPortion = `${maxHourString} ${maxMinuteString}`.trim()
  return `${minPortion} - ${maxPortion}`
}

export default api
