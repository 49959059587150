export const NODE_ENV = process.env.NODE_ENV

export const PROD = process.env.VUE_APP_API_ENV === 'production'
export const STAGING = process.env.VUE_APP_API_ENV === 'staging'
export const TEST = process.env.NODE_ENV === 'test'

export const DEV =
  process.env.NODE_ENV === 'development' || (!PROD && !STAGING && !TEST)

export const getLaunchDarklySdkKey = () => {
  if (PROD) {
    return '5d139dac294d47075eabd1d6'
  }

  if (STAGING) {
    return '5d139dac294d47075eabd1d5'
  }

  if (DEV || TEST) {
    return '5dc088fbea558a0875f7e289'
  }
}

export const getStripeSdkKey = () => {
  if (PROD) {
    return 'pk_live_f0497f8kmi7hbbfw3dlrY9xr'
  }

  return 'pk_test_t2uhIjaogVCjEkIN6hUROSnZ'
}

export const getBaseUrl = () => {
  if (PROD) {
    return 'https://loyaltyapi.wisely.io/v2'
  }

  if (STAGING) {
    return 'https://staginghostapi.wisely.io/v2'
  }

  if (DEV || TEST) {
    return 'http://localhost:8082/v2'
  }
}

export const getCrmUrl = () => {
  if (PROD) {
    return 'https://api.wisely.io/crm'
  }

  if (STAGING) {
    return 'https://stagingapi.wisely.io/crm'
  }

  if (DEV || TEST) {
    return 'http://localhost:8877/crm'
  }
}

export const getPaymentsBaseUrl = () => {
  if (PROD) {
    return 'https://api.wisely.io/payments'
  }

  if (STAGING) {
    return 'https://stagingapi.wisely.io/payments'
  }

  if (DEV || TEST) {
    return 'http://localhost:8877/payments'
  }
}

export const getHostBaseUrl = () => {
  if (PROD) {
    return 'https://api.wisely.io/host'
  }

  if (STAGING) {
    return 'https://stagingapi.wisely.io/host'
  }

  if (DEV || TEST) {
    return 'http://localhost:8877/host'
  }
}

export const getFormsBaseUrl = () => {
  if (PROD) {
    return 'https://api.wisely.io/forms'
  }

  if (STAGING) {
    return 'https://stagingapi.wisely.io/forms'
  }

  if (DEV || TEST) {
    return 'http://localhost:8877/forms'
  }
}

export const getInventoryBaseUrl = () => {
  if (PROD) {
    return 'https://api.wisely.io/inventory'
  }

  if (STAGING) {
    return 'https://stagingapi.wisely.io/inventory'
  }

  if (DEV || TEST) {
    return 'http://localhost:8877/inventory'
  }
}

export const getAPIBaseUrl = () => {
  if (PROD) {
    return 'https://api.wisely.io'
  }

  if (STAGING) {
    return 'https://stagingapi.wisely.io'
  }

  if (DEV || TEST) {
    return 'http://localhost:8877'
  }
}
