import _isObject from 'lodash/isObject'

import { DEV } from '@/helpers/env'

export default function (logged: string | object) {
  if (!DEV) { // TODO: log stuff to production
    return
  }

  const toBeLogged = _isObject(logged) ? logged : { message: logged }

  const {
    message,
    // requestURL,
    // request,
    // response,
    error
  } = toBeLogged as any

  // eslint-disable-next-line no-console
  const logMethod = error ? console.error : console.log

  logMethod('message:', message || (error || {}).message, 'error:', error)
}
