import Vue from 'vue'
import Vuex from 'vuex'

import api from '@/store/modules/api'
import reservationSearch from '@/store/modules/reservationSearch'
import reservationTimer from '@/store/modules/reservationTimer'
import waitTimeSync from '@/store/modules/waitTimeSync'
import featureFlags from '@/store/modules/featureFlags'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    api,
    featureFlags,
    reservationSearch,
    reservationTimer,
    waitTimeSync,
  },
})
